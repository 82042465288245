import requests from "./request";


// 课程详情  reqKnowledgeDetail
export  const reqKnowledgeDetail = (formData) => {
    return requests({
        url: `/api/article/allList?${formData}`,
        method: "GET"
    })
}

// 文章详情  reqArticleDetails
export  const reqArticleDetails = (formData) => {
    return requests({
        url: `/api/article/articleDetails/${formData}`,
        method: "GET"
    })
}

// 文章目录 reqArticleInfoList
export  const reqArticleInfoList = (formData) => {
    return requests({
        url: `/api/article/articleInfo/${formData}`,
        method: "GET"
    })
}

// 文章帮助列表  reqArticleHelpList
export  const reqArticleHelpList = (formData) => {
    return requests({
        url: `/api/article/articleHelp/${formData}`,
        method: "GET"
    })
}
// -----------------------------------------------------------------------------------


// -----------------------------------------------------------------------------------
// 课程列表
export  const reqKnowledgeAllList = () => {
    return requests({
        url: "/api/knowledge/allList",
        method: "GET"
    })
}

// 通过专栏查询课程列表 reqKnowledgeByColumnList
export  const reqKnowledgeByColumnList = (formData) => {
    return requests({
        url: "/api/knowledge/KnowledgeByColumn/" + formData,
        method: "GET"
    })
}
// -----------------------------------------------------------------------------------


// -----------------------------------------------------------------------------------

// 导航实例
export  const reqNavigationItemList = () => {
    return requests({
        url: "/resource/navigationItem/allList",
        method: "GET"
    })
}

// 通过菜单id查询  reqNavigationItemByMenuId
export  const reqNavigationItemByMenuId = (formData) => {
    return requests({
        url: `/resource/navigationItem/itemsByMenuId/${formData}`,
        method: "GET"
    })
}
// -----------------------------------------------------------------------------------

// 列表  reqCommunicationGroupsList
export  const reqCommunicationGroupsList = () => {
    return requests({
        url: "/resource/groups/allList",
        method: "GET"
    })
}


// -----------------------------------------------------------------------------------
// reqScripturesItemList
export  const reqScripturesItemList = () => {
    return requests({
        url: "/resource/learnItem/scripturesItemList",
        method: "GET"
    })
}
// -----------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------
// reqHoardItemList
export  const reqHoardItemList = () => {
    return requests({
        url: "/resource/resourceItem/allList",
        method: "GET"
    })
}
// -----------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------
// reqTabItemList
export  const reqTabItemList = (formData) => {
    return requests({
        url: `/resource/learnItem/itemMenuList/${formData}`,
        method: "GET"
    })
}
// reqHoardTabItemList
export  const reqHoardTabItemList = (formData) => {
    return requests({
        url: `/resource/resourceItem/itemList/${formData}`,
        method: "GET"
    })
}

// reqResourceDetail
export  const reqResourceDetail = (formData) => {
    return requests({
        url: `/resource/resourceItem/details/${formData}`,
        method: "GET"
    })
}
// -----------------------------------------------------------------------------------



// -----------------------------------------------------------------------------------
// reqFunnyList
export  const reqFunnyList = (formData) => {
    return requests({
        url: `/resource/funnyDay/list?${formData}`,
        method: "GET"
    })
}

// reqFunnyDayDetail
export  const reqFunnyDayDetail = (formData) => {
    return requests({
        url: `/resource/funnyDay/FunnyDayDetail/${formData}`,
        method: "GET"
    })
}

// -----------------------------------------------------------------------------------
