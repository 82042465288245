<template>
  <el-menu class="el-menu" mode="horizontal" :ellipsis="false" :router="true">
    <el-menu-item class="navMenu">
      <img
        class="navImage"
        src="/images/shanhai.png"
        alt="logo"
        @click.stop="reloadPage"
      />
    </el-menu-item>
    <el-menu-item index="/home" class="mobile">序</el-menu-item>
    <el-menu-item index="/course"
      ><span class="mobile">山海</span>异兽</el-menu-item
    >
    <el-menu-item index="/scriptures"
      ><span class="mobile">山海</span>藏经</el-menu-item
    >
    <el-menu-item index="/hoard"
      ><span class="mobile">山海</span>密藏</el-menu-item
    >
    <el-menu-item index="/portal"
      ><span class="mobile">山海</span>相逢</el-menu-item
    >
    <el-menu-item index="/funny"
      ><span class="mobile">山海</span>驿站</el-menu-item
    >
    <el-menu-item index="/traveler" class="mobile">山海行者</el-menu-item>
    <!-- <el-menu-item index="/search"
      ><span class="mobile">搜索</span></el-menu-item
    >
    <el-menu-item class="mobile" @keydown="keyDown">收藏</el-menu-item> -->
  </el-menu>
</template>
  
  <script >
export default {
  name: "zqrHeader",
  methods: {
    reloadPage() {
      // 刷新页面
      location.href = "/";
    },
    keyDown(e) {
      if (e.ctrlKey) {
        console.info(e)
      }
    },
  },
};
</script>
  
  <style scoped>
.el-menu.el-menu--horizontal .el-menu-item {
  font-size: 1.5rem;
  --el-menu-active-color: coral;
  --el-menu-hover-text-color: coral;
}

.el-menu--horizontal > .el-menu-item:nth-child(8) {
  margin-right: auto;
}

.el-menu {
  padding-right: 5rem;
}
.navMenu {
  padding: 0px;
}
.navImage {
  width: 11rem;
  height: 100%;
}
@media screen and (max-width: 100rem) {
  .el-menu--horizontal {
    display: flex;
    flex-wrap: nowrap;
    border-right: none;
    height: var(--el-menu-horizontal-height);
    justify-content: center;
  }
}

@media screen and (max-width: 100rem) {
  .navMenu,
  .mobile {
    display: none;
  }
}
</style>
  